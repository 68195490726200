import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Footer() {
    return(
        <Container>
            <Row>
                <Col sm={12} md={6}>
                    <div>
                        <a href="https://uk.linkedin.com/in/ville-kuosmanen-891889154">LinkedIn</a>
                    </div>
                    <div>
                        <a href="https://github.com/villekuosmanen">GitHub</a>
                    </div>
                </Col>
                <Col>
                    <div>
                        <a href='https://medium.com/@villekuosmanen'>Blog</a>
                    </div>
                    <div>
                        <a href='https://twitter.com/VilleKuosmanen'>X</a>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>© Ville Kuosmanen (2024)</div>
                </Col>
            </Row>
            </Container>
    );
}

export default Footer;
                