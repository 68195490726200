import React from 'react';
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Blog() {
    return (
        <div>
            <h1 id="blog" >Blog</h1>
            <p>My blog is hosted on <a className="blog-link-body" href='https://medium.com/@villekuosmanen' >Medium</a>. I tend to write about the the future of our society, viewed through the lens of technology. I also write technical blog posts on occassion.</p>
            <p>Here's a collection of blog posts that I consider as some of my best work.</p>
            <Container className="blog-container" >
                <Row>
                    <Col>
                        <h2>Non-technical</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <a href="https://villekuosmanen.medium.com/aldix-and-the-book-of-life-5da3f0001b6c" className="blog-link"
                        >
                            <Card className="blog-card">
                                <Card.Img variant="top" src="https://miro.medium.com/v2/1*ohJI8TuASEtKkTSE8kyQlg.png" className='blog-image' />
                                <Card.Body >
                                    <Card.Title>Aldix and the Book of Life</Card.Title>
                                    <Card.Text className="blog-description" >
                                        A fable on the dangers of measures becoming targets
                                    </Card.Text>
                                    <Card.Text>
                                        <small className="text-muted">6 min read (2023)</small>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                    <Col xs={12} sm={6}>
                        <a href="https://villekuosmanen.medium.com/it-needs-to-get-worse-before-it-gets-better-10bbf5ce36cc" className="blog-link"
                        >
                            <Card className="blog-card">
                                <Card.Img variant="top" src="https://miro.medium.com/v2/1*bMNcBnuVyZ1KEj2VvrP5vA.png" className='blog-image' />
                                <Card.Body >
                                    <Card.Title>It needs to get worse before it gets better</Card.Title>
                                    <Card.Text className="blog-description" >
                                        One way to approach the benefits and costs of changing your ways of working.
                                    </Card.Text>
                                    <Card.Text>
                                        <small className="text-muted">3 min read (2023)</small>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <a href="https://villekuosmanen.medium.com/blockchains-and-interoperability-the-case-for-crypto-as-social-innovation-e891d767c4f4" className="blog-link"
                        >
                            <Card className="blog-card">
                                <Card.Img variant="top" src="https://miro.medium.com/max/1200/0*3BLFezyM05q6yeFX" className='blog-image' />
                                <Card.Body >
                                    <Card.Title>Blockchains and interoperability — the case for crypto as social innovation</Card.Title>
                                    <Card.Text className="blog-description" >
                                        Is crypto a technological revolution that will remake the world in its image, just like the internet and iPhone did?
                                    </Card.Text>
                                    <Card.Text>
                                        <small className="text-muted">13 min read (2022)</small>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                    <Col xs={12} sm={6}>
                        <a href="https://medium.com/age-of-awareness/professors-as-creators-how-the-creator-economy-could-transform-education-99f6bef678f8" className="blog-link"
                        >
                            <Card className="blog-card">
                                <Card.Img variant="top" src="https://miro.medium.com/max/1200/0*KtJ-niNBwyn3k8wO" className='blog-image' />
                                <Card.Body >
                                    <Card.Title>Professors as Creators — how the Creator Economy could transform education</Card.Title>
                                    <Card.Text className="blog-description" >
                                        When choosing a university to attend, which factors do prospective students look for the most?
                                    </Card.Text>
                                    <Card.Text>
                                        <small className="text-muted">4 min read (2022)</small>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>Technical</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <a href="https://towardsdatascience.com/predicting-formula-1-results-with-elo-ratings-908470694c9c" className="blog-link"
                        >
                            <Card className="blog-card">
                                <Card.Img variant="top" src="https://miro.medium.com/max/1200/0*CqrQVe9sxJCrKRjR" className='blog-image' />
                                <Card.Body >
                                    <Card.Title>Predicting Formula 1 results with Elo Ratings</Card.Title>
                                    <Card.Text className="blog-description" >
                                        Building predictions with a model, data pipelines and a Monte Carlo simulation
                                    </Card.Text>
                                    <Card.Text>
                                        <small className="text-muted">5 min read (2020)</small>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                    <Col xs={12} sm={6}>
                        <a href="https://towardsdatascience.com/what-is-the-radical-content-problem-and-does-your-recommender-system-suffer-from-it-7fe017f9a8b1" className="blog-link"
                        >
                            <Card className="blog-card">
                                <Card.Img variant="top" src="https://miro.medium.com/max/1200/0*KrLpJJQmva9Htvz4" className='blog-image' />
                                <Card.Body >
                                    <Card.Title>What is the radical content problem, and does your recommender system suffer from it?</Card.Title>
                                    <Card.Text className="blog-description" >
                                        How explainable recommendations can help you discover new insights.
                                    </Card.Text>
                                    <Card.Text>
                                        <small className="text-muted">4 min read (2020)</small>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Blog;
