import React from 'react';
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import axios from 'axios';

function Projects() {
    return(
        <>
            <h1 id="projects" >Projects</h1>
            <div>List of side projects I have built over the years. In addition to these, I have built an AI Agent library called <a href='https://github.com/villekuosmanen/voyage-agents'>Voyage Agents</a> that's designed for smaller models running on local GPUs.</div>
            <Container className="projects-container" >
                <Row>
                    <Col xs={12} sm={6}>
                        <Card className="projects-card">
                            <a href="https://github.com/villekuosmanen/physiology-sim" >
                                <Card.Img variant="top" src="physiologysim_gif.gif" alt="Animated gif of a visual physiology simulator" className='blog-image'/>
                            </a>
                            <Card.Body>
                                <Card.Title>Human physiology simulator</Card.Title>
                                <Card.Text>
                                    A 24-hour hackathon project. Simulates blood circulation and the activation of the sympathetic nervous system during exercise and rest.
                                </Card.Text>
                                <Card.Link href="https://github.com/villekuosmanen/physiology-sim">Code</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Card className="projects-card">
                            <a href="https://villekuosmanen.github.io/F1PredictWeb/" >
                                <Card.Img variant="top" src="F1Project_image.png" alt="Screenshot of Formula 1 predictions" className='blog-image'/>
                            </a>
                            <Card.Body>
                                <Card.Title>Formula 1 prediction app</Card.Title>
                                <Card.Text>
                                    An application that uses statistical analysis, machine learning and Monte Carlo simulation to predict the results of Formula 1 qualifications and races. Built with Python and React.js.
                                </Card.Text>
                                <Card.Link href="https://villekuosmanen.github.io/F1PredictWeb/">Website</Card.Link>
                                <Card.Link href="https://github.com/villekuosmanen/F1Predict">Code</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <Card className="projects-card">
                            <a href="https://villekuosmanen.com/erbion" >
                                <Card.Img variant="top" src="erbion_image.png" alt="Screenshot of a chemistry lab application" className='blog-image'/>
                            </a>
                            <Card.Body>
                                <Card.Title>Erbion</Card.Title>
                                <Card.Text>
                                    Erbion is a platform for teaching Chemistry in secondary school level. Built with React.js.
                                </Card.Text>
                                <Card.Link href="https://villekuosmanen.com/erbion">Demo app</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Card className="projects-card">
                            <a href="https://github.com/villekuosmanen/SHProject">
                                <Card.Img variant="top" src="dissertation_image.png" alt="Screenshot of a movie recommendation application" className='blog-image'/>
                            </a>
                            <Card.Body>
                                <Card.Title>Dissertation</Card.Title>
                                <Card.Text>
                                    My BSc (Computer Science) dissertation, <i>Improving Explainability in user-facing recommender systems</i>, completed in April 2020.
                                </Card.Text>
                                <Card.Link href="https://github.com/villekuosmanen/SHProject">Code</Card.Link>
                                <Card.Link href="https://villekuosmanen.com/dissertation.pdf">Report</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Projects;