import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';

import About from './About';
import Blog from './Blog';
import Projects from './Projects';
import Footer from './Footer'

import './App.scss';

function App() {
  return (
    <div>
        <div className="App">
            <Image className="cover" src="cover1.png" alt="An illustration of futuristic electronic circuitry." />
            <Navbar className="nav-bar-background" variant="primary" >
                <Nav className="nav-bar">
                    <Nav.Link className="nav-link" href="#about">About</Nav.Link>
                    <Nav.Link className="nav-link" href="#blog">Blog</Nav.Link>
                    <Nav.Link className="nav-link" href="#projects">Projects</Nav.Link>
                </Nav>
            </Navbar>
            <div className="social-media-icons-row" >
                <a href="https://uk.linkedin.com/in/ville-kuosmanen-891889154" >
                    <img className="social-media-icon" src="icon_linkedin.png" alt="LinkedIn icon" width="38px" height="38px"></img>
                </a>
                <a href="https://medium.com/@villekuosmanen" >
                    <img className="social-media-icon" src="icon_medium.png" alt="Medium icon" width="50px" height="50px"></img>
                </a>
                <a href="https://twitter.com/VilleKuosmanen" >
                    <img className="social-media-icon" src="icon_x.png" alt="X icon" width="32px" height="32px"></img>
                </a>
            </div>
            <div className="content">
                <div className="main-section">
                    <About />
                </div>
                <div className="main-section">
                    <Blog />
                </div>
                <div className="main-section">
                    <Projects id="projects" />
                </div>
            </div>
        </div>
        <div>
            <div className="main-section footer">
                <Footer />
            </div>
        </div>
    </div>
  );
}

export default App;
