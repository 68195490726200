import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function About() {
    return(
        <Container>
            <Row>
                <Col sm={12} md={4}>
                    <Image roundedCircle className="profile" src="profile.jpg" id="about" alt="Ville's profile picture" />
                </Col>
                <Col sm={12} md={8}>
                    <h1>Ville Kuosmanen</h1>
                    <p><i>Founder of Voyage Robotics, building the future 🛳️</i></p>
                    <p>I am an entrepreneur and software engineer based in London. After earning my BSc in Computer Science from the University of St Andrews in 2020, I worked as an engineer in London's growing FinTech scene until founding Voyage Robotics in March 2024. I have built my reputation on designing and building simple yet effective solutions, eschewing complex tools such as Kubernetes or Kafka in favour of simple architectures fit for the problem at hand, not the problem Google or Facebook has. This doesn’t always make you the most popular person in the engineering floor where people love shiny new toys, but the results speak for themselves. Today, I am applying these learnings to robotics and embodied AI, where most software is build for research purposes and needs to be redesigned to power the applications of tomorrow.</p>
                    <p>I'm a bit of a tech geek and most of my free time is spent building cool stuff. On holidays I switch off my devices to enjoy and explore the great outdoors with my family. If you wish to contact me, you can reach out to me on LinkedIn, or email me at ville(at)villekuosmanen.com.</p>
                </Col>
            </Row>
            </Container>
    );
}

export default About;